<template>
    <div class="px-4">
        <!-- Header Section -->
        <div class="c-card u-p-small u-mb-small mx-2 pb-2 pt-3 sticky-card">
            <div class="row position-relative">
                <div class="col-md-6 pr-6">
                    <h4 class="mb-1">Title here</h4>
                    <p class="mb-1 mr-2"><strong>Other details</strong> - </p>
                </div>
            </div>
        </div>  
    
        <!-- Quick Info -->
        <infoFilterDetails></infoFilterDetails>

        <div class="c-card u-p-small u-mb-small mx-2 pb-2 pt-3"> 
            List here
        </div>
    </div>
</template>

<script>
import infoFilterDetails from "@/components/infoFilters/infoFilterDetails.vue";

export default {
    components: {
        infoFilterDetails,
    },

    data() {
        return {
        };
    },
};
</script>

